import * as tslib_1 from "tslib";
import { ElementRef, EventEmitter, OnInit } from '@angular/core';
import { NFT_POPUP_TYPE } from "./investments-nft-popup.config";
import { PROJECT_CONFIG } from "../../../config/project";
import { CRYPTO_AMOUNT_MASK } from "../../../config/crypto_settings";
var InvestmentsNftPopupComponent = /** @class */ (function () {
    function InvestmentsNftPopupComponent(investmentsService, cryptoTransactionsService, loaderService, fitTextService, NFTService, cashappPayService, partnerService, popupComponent) {
        this.investmentsService = investmentsService;
        this.cryptoTransactionsService = cryptoTransactionsService;
        this.loaderService = loaderService;
        this.fitTextService = fitTextService;
        this.NFTService = NFTService;
        this.cashappPayService = cashappPayService;
        this.partnerService = partnerService;
        this.popupComponent = popupComponent;
        this.isMascot = false;
        this.close = new EventEmitter();
        this.POPUP_TYPES = NFT_POPUP_TYPE;
        this.PROJECT_CONFIG = PROJECT_CONFIG;
        this.CRYPTO_AMOUNT_MASK = CRYPTO_AMOUNT_MASK;
        this.selectedImage = false;
        this.showTermsAndConditions = false;
        this.showCashAppAttention = true;
    }
    InvestmentsNftPopupComponent.prototype.ngOnInit = function () {
    };
    InvestmentsNftPopupComponent.prototype.closePopup = function (reload) {
        if (reload === void 0) { reload = false; }
        this.close.emit(reload);
        document.body.classList.remove('hide-menu');
        this.selectedImage = false;
        this.showTermsAndConditions = false;
        this.cashappPayService.destroyCashApp();
    };
    InvestmentsNftPopupComponent.prototype.ngOnChanges = function () {
        var _this = this;
        if (this.popupData) {
            //this.showCashAppAttention = this.popupData.element.cashappCompliance && this.popupData.element.cashappCompliance.show;
            document.body.classList.add('hide-menu');
            setTimeout(function () {
                document.body.querySelectorAll('.snake-btn').forEach(function (el) {
                    el.classList.add('is-notouched');
                });
                _this.imageUploading();
            });
        }
    };
    InvestmentsNftPopupComponent.prototype.isShowCashappCompliance = function () {
        console.log(this.popupData.element);
        if (this.popupData.element.cashappCompliance &&
            this.popupData.element.cashappCompliance.show) {
            return true;
        }
        return false;
    };
    InvestmentsNftPopupComponent.prototype.imageUploading = function () {
        if (document.querySelector('input[type="file"]')) {
            var $this_1 = this;
            document.querySelector('input[type="file"]').addEventListener('change', function () {
                if (this.files && this.files[0]) {
                    var img = document.querySelector('.js-mint-image');
                    img.src = URL.createObjectURL(this.files[0]); // set src to blob url
                    $this_1.selectedImage = true;
                }
            });
        }
    };
    InvestmentsNftPopupComponent.prototype.onConfirmCryptoSpend = function () {
        var _this = this;
        var amount = this.popupData.amount;
        var address = this.popupData.address;
        var note = this.popupData.note;
        var currencyId = this.popupData.currencyId;
        if (address && currencyId && amount) {
            this.investmentsService.spendCrypto(amount, address, currencyId, note).subscribe(function (data) {
                _this.checkConfirm(data);
            }, function (error) {
                _this.loaderService.hideAndShowTryAgain();
            }, function () {
                _this.loaderService.hide();
            });
        }
        else {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    InvestmentsNftPopupComponent.prototype.onConfirmTrade = function () {
        var _this = this;
        var from = this.popupData.currencyIdFrom;
        var to = this.popupData.currencyIdTo;
        var amount = this.popupData.amount;
        if (from && to && amount) {
            this.investmentsService.confirmTradeResult(from, to, amount).subscribe(function (data) {
                _this.checkConfirm(data);
            }, function (error) {
                _this.loaderService.hideAndShowTryAgain();
            }, function () {
                _this.loaderService.hide();
            });
        }
        else {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    InvestmentsNftPopupComponent.prototype.checkConfirm = function (data) {
        try {
            if (data.status === true) {
                this.popupComponent.showPopupSuccess({ text: data.message });
                this.closePopup();
            }
            else if (data.status === false) {
                this.popupComponent.showPopupError({ text: data.message });
            }
            else {
                this.loaderService.hideAndShowTryAgain();
            }
        }
        catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    InvestmentsNftPopupComponent.prototype.mintNFT = function () {
        var _this = this;
        this.popupComponent.showPopupConfirmCancel({
            text: 'Please, confirm minting NFT with selected image',
        }, function (status) {
            if (status) {
                _this.mintNFTConfirmed();
            }
        });
    };
    InvestmentsNftPopupComponent.prototype.mintNFTConfirmed = function () {
        var _this = this;
        var selectedImage = this.imageInput.nativeElement.files.item(0);
        this.NFTService.mintNFT(selectedImage).subscribe(function (data) {
            _this.checkConfirmImageLoaded(data);
        }, function (error) {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
        });
    };
    InvestmentsNftPopupComponent.prototype.checkConfirmImageLoaded = function (data) {
        try {
            if (data.body && data.body.status === true) {
                this.popupComponent.showPopupSuccess({ text: data.body.message });
                this.closePopup(true);
            }
            else if (data.body && data.body.status === false) {
                this.popupComponent.showPopupError({ text: data.body.message });
            }
            else {
                // this.loaderService.hideAndShowTryAgain();
            }
        }
        catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    // selectImage(imageInput) {
    //     imageInput.click();
    // }
    InvestmentsNftPopupComponent.prototype.getSelectedImage = function () {
        return this.selectedImage ? this.selectedImage : PROJECT_CONFIG.defaultImage2;
    };
    InvestmentsNftPopupComponent.prototype.onDepositConfirm = function () {
        this.close.emit(true);
        document.body.classList.remove('hide-menu');
    };
    InvestmentsNftPopupComponent.prototype.onShowTermsAndConditions = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.showTermsAndConditions = true;
                        if (!(this.popupType === this.POPUP_TYPES.cashApp)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.cashappPayService.showCashAppPayButton(this.popupData.element.amount)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    InvestmentsNftPopupComponent.prototype.checkTermsAgreeUncheck = function () {
        if (this.termsAccept) {
            return !this.termsAccept.nativeElement.checked;
        }
        return true;
    };
    InvestmentsNftPopupComponent.prototype.isPartnerTypeSingleBalance = function () {
        return this.partnerService.isPartnerTypeSingleBalance();
    };
    return InvestmentsNftPopupComponent;
}());
export { InvestmentsNftPopupComponent };
