import {Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {InvestmentsService} from "../../../service/investments/investments.service";
import {LoaderService} from "../../../service/loader.service";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {FitTextService} from "../../../service/fitTextService.service";
import {PrizesService} from "../../../service/prizes/prizes.service";
import {CryptoTransactionsService} from "../../../service/cryptoTransactions.service";
import {NFT_POPUP_TYPE} from "./investments-nft-popup.config";
import set = Reflect.set;
import {PROJECT_CONFIG} from "../../../config/project";
import {formatCurrency, formatNumber} from "@angular/common";
import {CRYPTO_AMOUNT_MASK} from "../../../config/crypto_settings";
import {NFTService} from "../../../service/nft/nft.service";
import {PartnerService} from "../../../service/partner.service";
import {CashappPayService} from "../../../service/cashapp-pay.service";

@Component({
    selector: 'app-investments-nft-popup',
    templateUrl: './investments-nft-popup.component.html',
    styleUrls: ['./investments-nft-popup.component.scss']
})
export class InvestmentsNftPopupComponent implements OnInit {
    @Input() popupData;
    @Input() popupType;
    @Input() isMascot = false;

    @Output() close = new EventEmitter();

    POPUP_TYPES = NFT_POPUP_TYPE;
    PROJECT_CONFIG = PROJECT_CONFIG;
    CRYPTO_AMOUNT_MASK = CRYPTO_AMOUNT_MASK;

    selectedImage = false;
    showTermsAndConditions = false;

    @ViewChild('imageInput', {static: false}) imageInput;
    @ViewChild('termsAccept', {static: false}) termsAccept: ElementRef;

    showCashAppAttention = true;

    constructor(private investmentsService: InvestmentsService,
                private cryptoTransactionsService: CryptoTransactionsService,
                private loaderService: LoaderService,
                private fitTextService: FitTextService,
                private NFTService: NFTService,
                private cashappPayService: CashappPayService,
                private partnerService: PartnerService,
                private popupComponent: PopupComponent,) {

    }


    ngOnInit() {



    }

    closePopup(reload = false) {
        this.close.emit(reload);
        document.body.classList.remove('hide-menu');
        this.selectedImage = false;
        this.showTermsAndConditions = false;
        this.cashappPayService.destroyCashApp();
    }

    ngOnChanges() {
        if (this.popupData) {
            //this.showCashAppAttention = this.popupData.element.cashappCompliance && this.popupData.element.cashappCompliance.show;
            document.body.classList.add('hide-menu');

            setTimeout(() => {
                document.body.querySelectorAll('.snake-btn').forEach(el => {
                    el.classList.add('is-notouched');
                });

                this.imageUploading();
            });

        }
    }

  isShowCashappCompliance(){
      console.log(this.popupData.element)
      if(this.popupData.element.cashappCompliance &&
        this.popupData.element.cashappCompliance.show
      ){
        return true;
      }

      return false;
  }

    imageUploading() {
        if (document.querySelector('input[type="file"]')) {
            const $this = this;
            document.querySelector('input[type="file"]').addEventListener('change', function() {
                if (this.files && this.files[0]) {
                    var img = document.querySelector('.js-mint-image');

                    (img as HTMLImageElement).src = URL.createObjectURL(this.files[0]); // set src to blob url
                    $this.selectedImage = true;
                }
            });
        }
    }

    onConfirmCryptoSpend() {
        const amount = this.popupData.amount;
        const address = this.popupData.address;
        const note = this.popupData.note;
        const currencyId = this.popupData.currencyId;

        if (address && currencyId && amount) {
            this.investmentsService.spendCrypto(amount, address, currencyId, note).subscribe(data => {
                this.checkConfirm(data);
            }, (error) => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    onConfirmTrade() {
        const from = this.popupData.currencyIdFrom;
        const to = this.popupData.currencyIdTo;

        const amount = this.popupData.amount;

        if (from && to && amount) {
            this.investmentsService.confirmTradeResult(from, to, amount).subscribe(data => {
                this.checkConfirm(data);
            }, (error) => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        } else {
            this.loaderService.hideAndShowTryAgain();
        }

    }

    checkConfirm(data) {
        try {
            if (data.status === true) {
                this.popupComponent.showPopupSuccess({text: data.message});
                this.closePopup();
            } else if (data.status === false) {
                this.popupComponent.showPopupError({text: data.message});
            } else {
                this.loaderService.hideAndShowTryAgain();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    mintNFT() {
        this.popupComponent.showPopupConfirmCancel({
            text: 'Please, confirm minting NFT with selected image',
        }, (status) => {
            if (status) {
                this.mintNFTConfirmed();
            }
        });
    }

    mintNFTConfirmed() {
        const selectedImage = this.imageInput.nativeElement.files.item(0);

        this.NFTService.mintNFT(selectedImage).subscribe(data => {
            this.checkConfirmImageLoaded(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    checkConfirmImageLoaded(data) {
        try {

            if (data.body && data.body.status === true) {
                this.popupComponent.showPopupSuccess({text: data.body.message});
                this.closePopup(true);
            } else if (data.body && data.body.status === false) {
                this.popupComponent.showPopupError({text: data.body.message});
            } else {
                // this.loaderService.hideAndShowTryAgain();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    // selectImage(imageInput) {
    //     imageInput.click();
    // }

    getSelectedImage() {
        return this.selectedImage ? this.selectedImage : PROJECT_CONFIG.defaultImage2;
    }

    onDepositConfirm() {
        this.close.emit(true);
        document.body.classList.remove('hide-menu');
    }

    async onShowTermsAndConditions() {
        this.showTermsAndConditions = true;

      if (this.popupType === this.POPUP_TYPES.cashApp) {
        await this.cashappPayService.showCashAppPayButton(this.popupData.element.amount);
      }
    }

    checkTermsAgreeUncheck() {
        if (this.termsAccept) {
            return !this.termsAccept.nativeElement.checked;
        }
        return true;

    }

    isPartnerTypeSingleBalance(): boolean {
        return this.partnerService.isPartnerTypeSingleBalance();
    }
}
